<template>
  <v-container fluid fill-height class="hero">
    <v-layout align-center justify-center>
      <v-flex xs12 sm8 md4>
        <v-card elevation="2">
          <v-toolbar dark color="primary">
            <v-toolbar-title>{{ $t('interface.reset_password') }}</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-form>
              <v-text-field
                  prepend-icon="mail"
                  name="email"
                  v-model="email"
                  v-bind:label="$t('profile.email')"
                  type="text"
              />
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-btn color="accent"
                   elevation="2"
                   x-large
                   block
                   @click.prevent="saveProfile"
            >{{ $t('profile.request_password') }}
            </v-btn>
          </v-card-actions>
          <v-card-actions>
            <a style="float: right; padding: 10px;" href="/">{{ $t('views.inloggen') }}</a>
          </v-card-actions>
        </v-card>
        <v-alert
            :value="show_error"
            type="error"
        >
          {{ error }}
        </v-alert>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import axios from 'axios'

export default {
  name: 'forgot-password',
  data: function () {
    return {
      show_error: false,
      error: '',
      email: '',
    }
  },
  methods: {
    saveProfile() {
      const url = process.env.VUE_APP_API_URL + '/api/password-reset/'
      axios.post(url, {
        email: this.email.trim(),
      })
          .then(() => {
            // Back to login
            this.$router.push('/')
          })
          .catch(err => {
            this.login_error = "Uh oh, something went wrong.. " + err.message,
                this.show_error = true
          })
    },
  }
}
</script>

<style>
.hero {
  background: url('/img/bevlogenheidsmeter-mobiel-nolost-mto.jpg');
  background-size: cover;
  height: 100vh;
}
</style>